<template>
    <font-awesome-icon
        v-bind="$attrs"
        :icon="[type, icon]"
        v-on="$listeners"
    />
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

export default defineComponent({
    components: {
        FontAwesomeIcon
    },
    props: {
        icon: { type: String, required: true },
        type: { type: String, default: 'fal' }
    }
});
</script>
